import { getInternationalFullDate, getInternationalTime } from '@utils/dates';
import { v2Links } from '@utils/navigation/links';
import Link from 'next/link';
import pluralize from 'pluralize';
import { SearchResultsProps } from '../../services/format-search-result-data';
import { SearchSkeleton } from '../SearchSkeleton/SearchSkeleton';

interface Props {
  inputValue?: string;
  isSearchInputOnFocus: boolean;
  isLoading: boolean;
  searchResult?: SearchResultsProps[];
}

const normalizeString = (name: string) => {
  return name.normalize('NFD').replaceAll(/[\u0300-\u036f]/g, '');
};

const getItemUrl = ({
  sectionTitle,
  slug,
  category,
  occurs_at_local,
  eventName,
  eventId,
  day,
  date,
  parentId,
}) => {
  if (sectionTitle === 'Location' && slug) {
    return `/city/${normalizeString(slug)}`;
  }

  if (category && slug) {
    return `/events/${category}/${slug}?parentId=${parentId}`;
  }

  if (occurs_at_local && day && date && eventId) {
    const eventLink = eventName
      .concat(' ', day, ' ', date)
      .replaceAll(' ', '-')
      .replaceAll('.', '');

    return `${v2Links.buy}?eventId=${eventId}&eventLink=${eventLink}`;
  }
};

export const SearchResults = ({
  isSearchInputOnFocus,
  searchResult,
  isLoading,
  inputValue,
}: Props) => {
  return (
    <div
      className={`${isSearchInputOnFocus ? 'block' : 'hidden'} relative z-10 h-full`}
    >
      <div className="absolute left-0 max-h-full w-full overflow-y-scroll rounded-b-xl bg-white pb-16 md:max-h-[546px] md:pb-0 md:shadow-lg">
        {isLoading && <SearchSkeleton />}
        {inputValue && searchResult && !searchResult?.length && !isLoading && (
          <div>
            <p className="py-10 text-center text-base text-gray-800">
              No results found
            </p>
          </div>
        )}
        {Boolean(searchResult?.length) && !isLoading && (
          <>
            {searchResult?.map(({ sectionItems, sectionTitle }) => (
              <div key={sectionTitle}>
                <div className="bg-gray-75">
                  <p className="px-5 py-3 text-sm font-semibold text-gray-500">
                    {sectionTitle}
                  </p>
                </div>
                {sectionItems.map((searchItem) => {
                  if (searchItem.PlaceId) {
                    return (
                      <Link
                        href={`/city/${normalizeString(
                          searchItem.Text.replaceAll(', ', '-')
                            .replaceAll(' ', '-')
                            .toLowerCase()
                        )}`}
                        className="flex flex-col border-t border-gray-200 p-5"
                        key={searchItem.PlaceId}
                      >
                        <h4 className="text-base text-gray-800">
                          {searchItem.Text}
                        </h4>
                        <span className="text-sm text-gray-500">
                          {`${searchItem.numberOfEvents} ${pluralize('event', searchItem.numberOfEvents)}`}
                        </span>
                      </Link>
                    );
                  }
                  const {
                    id,
                    occurs_at_local,
                    name,
                    venue,
                    events,
                    slug,
                    category,
                  } = searchItem;

                  const dateFull = occurs_at_local
                    ? getInternationalFullDate(
                        String(occurs_at_local),
                        undefined
                      ).split(', ')
                    : undefined;

                  const day = dateFull ? dateFull[0] : undefined;
                  const date = dateFull ? dateFull[1] : undefined;
                  const time = occurs_at_local
                    ? getInternationalTime(String(occurs_at_local), true)
                    : undefined;

                  console.log(category);
                  const itemUrl = getItemUrl({
                    category: category?.category?.slug,
                    sectionTitle,
                    slug,
                    occurs_at_local,
                    eventName: name,
                    day,
                    date,
                    eventId: id,
                    parentId: category?.category?.top_parent_id,
                  });

                  return (
                    <Link
                      target="_blank"
                      href={itemUrl ?? ''}
                      key={id}
                      prefetch={false}
                      className="flex border-t border-gray-200 p-5"
                    >
                      {occurs_at_local && (
                        <div className="mr-8 min-w-fit">
                          <h4 className="mb-1 text-base text-gray-800">
                            {date}
                          </h4>
                          <span className="text-sm text-gray-500">{time}</span>
                        </div>
                      )}
                      <div>
                        <h4 className="text-base text-gray-800">{name}</h4>
                        {!occurs_at_local && (
                          <span className="text-sm text-gray-500">
                            {`${events} ${pluralize('event', events)}`}
                          </span>
                        )}

                        {occurs_at_local && venue?.location && (
                          <>
                            <span className="text-sm text-gray-500">
                              {venue?.location}
                            </span>
                          </>
                        )}
                      </div>
                    </Link>
                  );
                })}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
