'use client';

import { useClickAway } from '@uidotdev/usehooks';
import { getArtistsByCategory } from '@v2/services/getArtistsByCategory/getArtistsByCategory';
import { ChevronDown, Country, HipHop, Pop, RAndB, Rock, type SVGComponentProps } from '@v2/ui';
import Link from 'next/link';
import { FC, useState } from 'react';

export enum SONG_CATEGORY {
  POP = 'POP',
  ALTERNATIVE_ROCK = 'ALTERNATIVE_ROCK',
  DANCE_ELECTRONIC = 'DANCE_ELECTRONIC',
  COUNTRY_FOLK = 'COUNTRY_FOLK',
  RAP_HIPHOP = 'RAP_HIPHOP',
  HARD_ROCK_METAL = 'HARD_ROCK_METAL',
}


const genreImagePath = '/concerts/genres'


export const genreCategoriesList = [
  {
    label: "Pop",
    value: SONG_CATEGORY.POP,
    slug: 'rock-pop',
  image: `${genreImagePath}/rock-pop.png`
  },
  {
    label: "Rock",
    value: SONG_CATEGORY.ALTERNATIVE_ROCK,
    slug: 'alternative-rock',
    image: `${genreImagePath}/alternative-rock.png`

  },
  {
    label: "Dance/Electronic",
    value: SONG_CATEGORY.DANCE_ELECTRONIC,
    slug: 'dance-electronic',
    image: `${genreImagePath}/dance-electronic.png`

  },

  {
    label: "Country",
    value: SONG_CATEGORY.COUNTRY_FOLK,
    slug: 'country-folk',
    image: `${genreImagePath}/country-folk.png`

  },
  {
    label: "Hip-Hop",
    value: SONG_CATEGORY.RAP_HIPHOP,
    slug: 'rap-hip-hop',
    image: `${genreImagePath}/rap-hip-hop.png`

  },
  {
    label: "Metal",
    value: SONG_CATEGORY.HARD_ROCK_METAL,
    slug: 'hard-rock-metal',
    image: `${genreImagePath}/hard-rock-metal.png`

  }
]
interface Props {
  buttonStyle: string;
  variant: 'black' | 'white';
}

export const ConcertsDropdown = ({ buttonStyle, variant }: Props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<SONG_CATEGORY>(
    SONG_CATEGORY.ALTERNATIVE_ROCK
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const teamsList = getArtistsByCategory(selectedMenuItem);

  const dropdownMenuRef = useClickAway<HTMLDivElement>(() => {
    setIsDropdownOpen(false);
  });

  const selectedLabel = genreCategoriesList.find(val => val.value === selectedMenuItem)!

  return (
    <div ref={dropdownMenuRef}>
      <button
        onClick={() => setIsDropdownOpen((oldValue) => !oldValue)}
        className={`flex items-center gap-2 rounded-lg border-2  ${isDropdownOpen ? buttonStyle : 'border-transparent'}`}
      >
        Concerts <ChevronDown stroke={variant} size="20" />
      </button>

      <div className="relative">
        <div
          className={`${isDropdownOpen ? 'flex' : 'hidden'} absolute z-10 mt-3 w-[800px] flex-col rounded-lg bg-white text-gray-800 shadow-lg `}
          ref={dropdownMenuRef}
        >
          <div className="w-full border-b border-gray-200 px-8 pt-5">
            <ul className="flex gap-x-8 overflow-scroll no-scrollbar" >
              {genreCategoriesList.map(({ label, value }) => (
                <li
                  className={` pb-5 ${selectedMenuItem === value ? 'border-b-2 border-primary-500' : ''} `}
                  key={value}
                >
                  <button
                    onClick={() => {
                      setSelectedMenuItem(value);
                    }}
                    className={`text-nowrap flex flex-row items-center gap-2 text-base font-semibold ${selectedMenuItem === value ? 'text-primary-500' : ''}`}
                  >
                    <GenreDropdownIcons
                      isSelected={selectedMenuItem === value}

                      size="20" genre={value} />
                    {label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <ul className="grid w-full grid-flow-row auto-rows-max grid-cols-3 gap-x-8 gap-y-3 p-8 text-left">
            {teamsList.map(({ artist, slug: artistSlug, categoryId, categorySlug }) => (
              <li key={artist}>
                <Link
                  prefetch={false}
                  href={`/events/${categorySlug}/${artistSlug}?parentId=${categoryId}`}

                  className="text-base font-semibold text-gray-800"
                >
                  {artist}
                </Link>
              </li>
            ))}
          </ul>
          <div className="flex items-center justify-center rounded-b-lg bg-gray-200 py-4">
            <Link
              prefetch={false}
              href={`/concerts`}
              className="w-full text-center text-gray-800"
            >
              View all concerts
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};




type GenreDropdownIconsProps = SVGComponentProps & { genre: SONG_CATEGORY, isSelected: boolean };

export const GenreDropdownIcons = ({ genre, isSelected, ...props }: GenreDropdownIconsProps) => {
  const iconByGenre: Record<SONG_CATEGORY, FC<SVGComponentProps>> = {
    POP: Pop,
    ALTERNATIVE_ROCK: Rock,
    DANCE_ELECTRONIC: HipHop,
    COUNTRY_FOLK: Country,
    RAP_HIPHOP: RAndB
    ,
    HARD_ROCK_METAL: Rock,
  };

  const Icon = iconByGenre[genre];

  return <Icon {...props} stroke={isSelected ? '#FA2846' : '#1D2939'} />;
};
