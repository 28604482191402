import { SONG_CATEGORY } from '@v2/containers/Header/components/ConcertsDropdown';
import alternativeRock from './ALTERNATIVE-ROCK.json';
import country from './COUNTRY_FOLK.json';
import danceElectronic from './DANCE-ELECTRONIC.json';
import hardRockMetal from './HARD-ROCK_METAL.json';
import pop from './POP.json';
import rapHiphop from './RAP_HIPHOP.json'


type ArtistCategory = {
  artist: string
  slug: string
  categoryId: number
  categoryName: string
  categorySlug: string
  parentCategoryId: number

}

export const getArtistsByCategory = (leagueName: string) => {
  const leaguesData: Record<SONG_CATEGORY, ArtistCategory[]> = {
    ALTERNATIVE_ROCK: alternativeRock,
    DANCE_ELECTRONIC: danceElectronic,
    COUNTRY_FOLK: country,
    POP: pop,
    HARD_ROCK_METAL: hardRockMetal,
    RAP_HIPHOP:rapHiphop

  };

  return leaguesData[leagueName];
};
