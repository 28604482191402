import routes from '@config/routes';
import { apiFetcher } from '@instance';
import { useClickAway } from '@uidotdev/usehooks';
import { AlertToast, AlertTriangle, SearchMD } from '@v2/ui';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useDebounceCallback } from 'usehooks-ts';
import { SearchResults } from './components/SearchResults/SearchResults';
import {
  formatSearchResultData,
  type SearchResultsProps,
} from './services/format-search-result-data';

export const HeaderSearch = ({ variant }: { variant: 'black' | 'white' }) => {
  const [isSearchInputOnFocus, setIsSearchInputOnFocus] = useState(false);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResultsProps[]>();
  const [searchValue, setSearchValue] = useState('');

  const toggleInputFocus = (override?: boolean) => {
    setIsSearchInputOnFocus((oldValue) => {
      if (typeof override !== 'undefined') {
        return override;
      }
      return !oldValue;
    });
  };

  const isBlackMode = variant === 'black';

  const debounceSearch = useDebounceCallback((searchValue: string) => {
    getAPIData(searchValue);
  }, 1000);

  const onChangeSearchInput = useCallback((value: string) => {
    if (!value.trimStart().length) setSearchResults([]);
    setSearchValue(value);
    if (value.trimStart().length < 3) return;
    setIsLoadingResults(true);
    debounceSearch(value.trimStart());
  }, []);

  const getAPIData = async (search: string) => {
    setIsLoadingResults(true);
    try {
      const { results, locations, events } = await apiFetcher(routes.search, {
        body: JSON.stringify({ q: search }),
      });


      const formattedSearchResult = formatSearchResultData(
        results,
        locations,
        events
      );


      setSearchResults(formattedSearchResult);
    } catch (e: any) {
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    } finally {
      setIsLoadingResults(false);
    }
  };

  const searchWrapperClasses = clsx(
    'flex border placeholder-gray-400 px-4 py-3 items-center gap-3 z-20 bg-white font-medium',
    {
      'w-[640px] text-gray-800': isSearchInputOnFocus,
      'rounded-lg':
        isSearchInputOnFocus && !searchResults?.length && !isLoadingResults,
      'rounded-t-lg':
        isSearchInputOnFocus && (searchResults?.length || isLoadingResults),
      'w-80 bg-opacity-15 rounded-lg': !isSearchInputOnFocus,
      'bg-slate-500': !isSearchInputOnFocus && !isBlackMode,
      'border-transparent': !isBlackMode,
      'border-gray-300': isBlackMode,
    }
  );

  const dropdownMenuRef = useClickAway<HTMLDivElement>(() => {
    toggleInputFocus(false);
  });

  return (
    <div
      ref={dropdownMenuRef}
      className={isSearchInputOnFocus ? 'absolute z-10' : ''}
    >
      <div className={searchWrapperClasses}>
        <SearchMD
          className={
            isSearchInputOnFocus ? 'text-primary-500' : 'text-gray-400'
          }
        />
        <input
          className="flex-1 bg-transparent outline-none"
          type="text"
          placeholder="Search event, team or location"
          value={searchValue}
          onChange={(e) => onChangeSearchInput(e.target.value)}
          onFocus={() => toggleInputFocus(true)}
        />
      </div>
      <SearchResults
        isLoading={isLoadingResults}
        isSearchInputOnFocus={isSearchInputOnFocus}
        searchResult={searchResults}
        inputValue={searchValue}
      />
    </div>
  );
};
