import instance from '@instance';
import devConsole from '@utils/developer-console';

export const formatLeagueSlug = (slug: string) => {
  if(!slug) return ''
  if (slug === 'ncaa--2') return 'NCAA-MENS-FOOTBALL';

  if (slug === 'ncaa-men--2') return 'NCAA-MENS-BASKETBALL';

  if (slug.startsWith('mls')) return 'MLS';
  return slug.toUpperCase();
};

export const getVenueImages = async (events) => {
  if (!events) return [];

  const venueIds = events?.map((event) => ({
    venueId: event?.venue?.id,
    league: formatLeagueSlug(event?.category?.slug),
  }));

  try {
    const body = JSON.stringify(venueIds);
    const imagesList = await instance.server('/image/venues', { body });
    const response = await imagesList.json();

    if (response?.error) return [];
    return response;
  } catch (e) {
    devConsole({ error: e });
    return [];
  }
};
